import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class FeaturedProjectsRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: projects } = data.allMarkdownRemark

    return (
      <div className="is-multiline">
        {projects &&
          projects.map(({ node: project }) => (
            <div key={project.id}>
              <article
                className={`blog-list-item project-list-item is-child box`}
              >
                <header>
                  {project.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: project.frontmatter.featuredimage,
                          alt: `featured image thumbnail for project ${project.frontmatter.title}`,
                          style: { borderRadius: '5px 5px 0px 0px' }
                        }}
                      />
                      {project.frontmatter.inProgress ? (
                        <div className="ribbon">
                            <span>In progress</span>
                        </div>
                  ) : null}
                    </div>
                  ) : null}
                  <p className="post-meta">
                    <Link
                      className="title has-text-primary is-size-3"
                      to={project.fields.slug}
                    >
                      {project.frontmatter.title}
                    </Link>
                    <span className="subtitle is-block">
                      {project.frontmatter.date}
                    </span>
                  </p>
                </header>
                <div className="blog-list-item-content">
                  <p>
                    {project.frontmatter.description}
                    <br />
                    <Link className="button" to={project.fields.slug}>
                      Keep Reading →
                    </Link>
                  </p>
                </div>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

FeaturedProjectsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query FeaturedProjectsRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___title] }
          filter: { frontmatter: { templateKey: { eq: "project-page" }, isFeatured: {eq: true } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                inProgress
                isFeatured
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <FeaturedProjectsRoll data={data} />}
  />
)
